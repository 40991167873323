import React, { useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { readString } from 'react-papaparse';
import { FiChevronUp, FiCalendar } from "react-icons/fi";
import Helmet from "./component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "./component/header/HeaderThree";
import FooterTwo from "./component/footer/FooterTwo";
import TabTwo from "./elements/tab/TabTwo";
import ContactThree from "./elements/contact/ContactThree";

import BlogContent from "./elements/blog/BlogContent";
import ConcertsCSV from '../data/NEW Schedule for Website.csv';
import BioPDF from '../data/Stella_Chen_2023_Bio.pdf';
import ChineseBioPDF from '../data/Stella_Chen_2023_Bio_Chinese.pdf';
import StringsMagazinePDF from '../data/StringsMagazine.pdf';
import YouTube from 'react-youtube';
import { Element } from "react-scroll";

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
];
const album_title = 'Stella x Schubert';
const album_description = ``;
const PostList = BlogContent;

const Background = () => {
    return (
        <Element name='home' id="home" className="fix">
        <div className="slider-wrapper">
            {/* Start Single Slide */}
                {SlideList.map((value , index) => (
                    <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25 full_height" style={{ backgroundImage: `url(${Background})` }} key={index}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.category ? <span>{value.category}</span> : ''}
                                        {
                                        <h1 className="title">Stella Chen <br/>
                                        <span> Gramophone Young Artist of the Year </span>
                                        </h1>      
                                        /*
                                        <h1 className="title">Stella Chen <br/>
                                        <TextLoop>
                                        <span> Violinist </span>
                                        <span> Avery Fisher Career Grant Recipient </span>
                                        <span> Lincoln Center Emerging Artist </span>
                                        </TextLoop>
                                        </h1>
                                        */}
                                        {/* <h2>based in USA.</h2> */}
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            {/* End Single Slide */}
            </div>
        </Element>
    )
}

class ConcertsList extends React.Component {
    render(){
        const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
            // weekday: "short",
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });

        let {concertsArray, column} = this.props;
        console.log("this.props.item: ", this.props);
        console.log("concertsArray: ", concertsArray);

        let utc_yesterday = new Date();
        utc_yesterday.setDate(utc_yesterday.getDate() - 1);

        let future_concerts_array = concertsArray.filter(function (el) {
            return new Date(el.Date) >= utc_yesterday;
        });

        return(
            <React.Fragment>
                <div className="row">
                    {future_concerts_array.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href={val.Link}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        <FiCalendar />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{longEnUSFormatter.format(new Date(val.Date))}</h3>
                                        <h3 className="title">{val.What}</h3>
                                        <p style={{margin: 0}}>{val.Time}</p>
                                        <p style={{margin: 0}}>{val.Venue}</p>
                                        <p style={{margin: 0}}>{val.Location}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}

const Concerts = (props) => {    
    let {concertsArray} = props;
    return (
        <Element name="concerts" id="concerts" className="fix ptb--60 bg_color--5">
            <div className="service-area creative-service-wrapper ptb--60" >
                <div className="container">
                    {/*
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                <h2 className="title">Concerts</h2>
                                <p>TODO</p>
                                {concertsArray.map((value , i ) => (
                                    <p>
                                        {value.Date}<br></br>
                                        {value.Time}<br></br>
                                        {value.What}<br></br>
                                        {value.Location}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                    */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                <h2 className="title">Concerts</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row creative-service">
                        <div className="col-lg-12">
                            <ConcertsList concertsArray={concertsArray} column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" item="6"/>
                        </div>
                    </div>
                </div>
            </div>  
        </Element>
    )
}

const StellaXSchubert = (props) => {  
    return (
        <Element name="stellaxschubert" id="stellaxschubert" className="fix">
            <div className="about-area bg_color--5">
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row pt--30">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center stream">
                                    <a className="rn-button-style--2 btn-solid" href="https://platoon.lnk.to/stellaxschubert"><span>Stream Stella x Schubert</span></a>
                                </div>
                            </div>
                        </div>

                        <div className="row row--35 align-items-center justify-content-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="assets/images/stella/Stella_x_Schubert_Liner_Note_FINAL_1_RESIZED.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="assets/images/stella/Stella_x_Schubert_Liner_Note_FINAL_2_RESIZED.png" alt="About Images"/>
                                </div>
                            </div>

                            {/*
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{album_title}</h2>
                                        <p className="description">{album_description}</p>
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>
                        <div className="row pt--30">
                            <div className="col-lg-12">
                                <blockquote>
                                <p class="quotation text-white-50">
                                The combination of her gleaming tone and staggering technique makes for music that commands attention at every moment… there’s no shortage of chills-engendering playing on this auspicious debut
                                </p>
                                </blockquote>
                                <p className="text-white-50 text-right">- Textura Reviews</p>

                                <div className="view-more-btn mt--30 mt_sm--30 text-center stream">
                                    <a className="rn-button-style--2 btn-solid" href="https://therehearsalstudio.blogspot.com/2023/03/stella-chen-brings-schubert-to-cmsf.html"><span>Read Textura Review of Stella x Schubert</span></a>
                                </div>

                                {/*
                                <blockquote>
                                <p class="quotation text-white-50">
                                [Stella Chen] delivered an account whose rhetorical foundations seized my attention from the opening measure and maintained its grip all the way to the final cadence of the concluding movement.
                                </p>
                                </blockquote>
                                <p className="text-white-50 text-right">- Stephen Smoliar (The Rehearsal Studio)</p>
                                <div className="view-more-btn mt--30 mt_sm--30 text-center stream">
                                    <a className="rn-button-style--2 btn-solid" href="https://therehearsalstudio.blogspot.com/2023/03/stella-chen-brings-schubert-to-cmsf.html"><span>Rehearsal Studio Review of Herbst Theatre Album Debut Concert</span></a>
                                </div>
                                */}
                            </div>
                        </div>
                        
                        <div className="text-center">
                            <YouTube videoId="cm3EZ9lrF3g" />
                        </div>

                        <p className="text-white-50 text-left pt--30 pb--50">My love for Schubert blossomed unexpectedly during a performance analysis class with Robert Levin, a brilliant musician I knew as the phenom who completed Mozart’s Requiem for his undergraduate thesis. Known as he is for his encyclopedic knowledge of Mozart, Levin surprised me when he revealed that his favorite piece was Schubert’s G major Quartet, D.887. He spoke about the music in a way that brought tears to my eyes, despite having never heard the piece myself. Thus began my deep and unwavering love affair with Schubert. For me, no composer conceives of more beautiful melodies than Schubert’s, in large part due to his extraordinary ability to translate vulnerability into music. Exposed, devastatingly fragile, almost otherworldly, his music teeters precariously on the brink of life and death. Rightfully wide-eyed but inevitably futile attempts to reproduce or quantify the magic of Schubert have preoccupied performers and scholars for the last two centuries. Having approached his music from both of those perspectives myself, I can only say: Schubert transports us to another world.
                            <p></p><p></p>
                            I asked to start playing the violin after being captivated by the sheer beauty of its tone; the endless search for that shiver-inducing sound is still the reason I reach for the violin every day, and nothing inspires me more than Schubert’s music to continue that aural adventure. Working on this project has been a gift. There is no better music to remind us to appreciate the most delicate and fragile beauty in the world.
                        </p>

                        
                    </div>
                    </div>
                </div>
        </Element>

    )
}

class StellaChen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            concertsArray: []
          // Initialize state
        };
    }
    componentDidMount() {
        const papaConfig = {
            complete: (results, file) => {
              console.log('Parsing complete:', results, file);
              this.setState({concertsArray: results.data});
            },
            error: (error, file) => {
              console.log('Error while parsing:', error, file);
            },
            download: true,
            header: true,
        };
        readString(ConcertsCSV, papaConfig);
    }
    /*
    componentDidMount() {
        const [concertsArray, setConcertsArray] = useState([]);
        const papaConfig = {
            complete: (results, file) => {
              console.log('Parsing complete:', results, file);
              setConcertsArray(results.data);
              console.log("concertsArray: ", concertsArray);
            },
            error: (error, file) => {
              console.log('Error while parsing:', error, file);
            },
            download: true,
            header: true,
        };
        readString(ConcertsCSV, papaConfig);

        // Initialize data
        this.setState({
          data: {
            // Your data here
          }
        });
    } */

    render() {
        return (
            <div className="active-dark">
                <Helmet pageTitle="Stella Chen, Violinist" />

                <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
                {/* Start Slider Area   */}
                <Background />
                {/* End Slider Area */} 
                {/* Start About Area */}
                <StellaXSchubert/>

                <Element name="biography" id="biography" className="fix ptb--60 bg_color--1">
                    <div className="container">
                        <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                            <h2 className="title bio-title">Biography</h2>
                        </div>

                        <blockquote>
                            <p className="quotation text-white-50">
                            Her graceful, singing line, gleaming tone, and breathtaking use of pianissimo made the Tully Hall crowd sit bolt upright. 
                            </p>
                        </blockquote>
                        <p className="text-white-50 text-right">- Musical America</p>
                        <p className="text-white-50 text-left">Gramophone 2023 Young Artist of the Year Stella Chen garnered worldwide attention with her first-prize win at the 2019 Queen Elizabeth International Violin Competition, followed by the 2020 Avery Fisher Career Grant and 2020 Lincoln Center Emerging Artist Award.

                        <p></p> <p></p>Since then, Stella has appeared across North America, Europe, and Asia in concerto, recital, and chamber music performances. She recently made debuts with the New York Philharmonic, Chicago Symphony, Minnesota Orchestra, Israel Philharmonic, Chamber Orchestra of Europe, Baltimore Symphony, Belgian National Orchestra, and many others and appeared at the Vienna Musikverein and Berlin Philharmonie.  In recital, recent appearances include Lincoln Center, Carnegie Hall, the Phillips Collection, Rockport Music Festival, and Nume Festival in Italy. She appears frequently with Chamber Music Society of Lincoln Center both in New York and on tour. 
                        
                        <p></p> <p></p>Stella has appeared as a chamber musician in festivals including the Ravinia, Seattle Chamber Music, Perlman Music Program, Music@Menlo, Bridgehampton, Rockport, Kronberg Academy, and Sarasota. Chamber music partners include Itzhak Perlman, James Ehnes, and Matthew Lipman.

                        <p></p> <p></p>She is the inaugural recipient of the Robert Levin Award from Harvard University, where she was inspired by Robert Levin himself. Teachers and mentors have included Donald Weilerstein, Itzhak Perlman, Miriam Fried, and Catherine Cho. She received her doctorate from the Juilliard School where she serves as teaching assistant to her longtime mentor Li Lin. 

                        <p></p> <p></p>Stella plays the 1720 General Kyd Stradivarius, on generous loan from Dr. Ryuji Ueno and Rare Violins In Consortium, Artists and Benefactors Collaborative and the 1708 Huggins Stradivarius courtesy of the Nippon Music Foundation.
                        </p>

                        <p class="text-white-50 text-center"><a href={BioPDF}>Link to Bio</a></p>
                        <p class="text-white-50 text-center"><a href={ChineseBioPDF}>中文个人简历</a></p>
                    </div>
                </Element>
                
                {/* End About Area */}

                {/* Start Concert Area  */}
                {/* TC */}
                <Concerts concertsArray={this.state.concertsArray}/>
                {/* End Service Area  */} 

                {/* Start Portfolio Area */}
                {/* TC */}
                {/*
                <div id="news" className="fix">
                    <div className="portfolio-area ptb--120 bg_color--1">
                        <div className="portfolio-sacousel-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                            <h2 className="title">Latest News V1</h2>
                                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                            <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                */}
                {/* End Portfolio Area */}

                {/* Start Blog Area */}
                <Element name="news" id="news" className="fix">
                    {/* TC */}
                    {/* <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30"> */}
                    <div className="portfolio-area ptb--60 bg_color--1">
                        <div className="container">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="section-title text-center pt--60">
                                        <h2>Latest News</h2>
                                        {/* TC */}
                                        {/* <p>There are many variations of passages of Lorem Ipsum available, <br />but the majority have suffered alteration.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt--60 mt_sm--40">
                                {PostList.map((value , i ) => (
                                    <div className="col-lg-4 col-md-6 col-12" key={i}>
                                        <div className="blog blog-style--1">
                                            <div className="thumbnail">
                                                <a href="/blog-details">
                                                    <img className="w-100" src={`assets/images/stella/news/${value.images}`} alt="Blog Images"/>
                                                </a>
                                            </div>
                                            <div className="content">
                                                <p className="blogtype">{value.category}</p>

                                                <h4 className="title"><a href={value.title == 'Magic Kingdom: Strings Magazine' ? StringsMagazinePDF : `${value.link}`}>{value.title}</a></h4>
                                                <div className="blog-btn">
                                                    <a className="rn-btn text-white" href={value.title == 'Magic Kingdom: Strings Magazine' ? StringsMagazinePDF : `${value.link}`}>Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* TC added */ }
                            {/* TODO: implement */}
                            {/*
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>
                                    </div>
                                </div>
                            </div>    
                            */}
                        </div>    
                    </div>
                </Element>
                {/* End Blog Area */}

                {/* Start Contact Area */}
                <Element name="contact" id="contact" className="fix ptb--150">
                    <div className="rn-contact-area bg_color--5">
                        <ContactThree contactImages="assets/images/stella/Stella_Chen_1289_FINAL_cropped_RESIZED.jpeg" contactTitle="Contact Stella" />
                    </div>
                </Element>
                {/* End COntact Area */}

                <FooterTwo />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
            </div>
        )
    }
}

export default StellaChen;
